<template>
  <div id="agent-report" class="container">

    <h3>รายงานแบ่งตามประเภทหวย</h3>
    <CardDateFrom :branches="[]" @selected="searchData" />

    <div v-if="items.length > 0">
      <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
      <div class="card mb-3 py-3">
        <bar-chart :chartdata="chartCollection" :options="chartOptions"></bar-chart>
        <div class="text-center mt-4">
          <b-form-checkbox-group
            v-model="chartSelected"
            :options="chartSelectOptions"
            name="chart-select"
          ></b-form-checkbox-group>
        </div>
      </div>
    </div>

    <div v-if="isManager" class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>
    <div class="card">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th rowspan="2" style="width: 20%" class="text-center">หวย</th>
            <th colspan="3" class="text-center">ยอดเว็บ</th>
          </tr>
          <tr>
            <td style="width: 12%;" class="text-center">ยอดขาย</td>
            <td style="width: 12%;" class="text-center">ยอดจ่าย</td>
            <td style="width: 12%;" class="text-center">คงเหลือ</td>
          </tr>
        </thead>
        <tbody v-for="group in Groups" :key="group._id">
          <tr>
            <td colspan="4" class="text-primary">{{group.item_name}}</td>
          </tr>
          <tr v-for="(item, index) in group.markets">
            <td class="text-left">{{ item.item_name }}</td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.sales)"></span></td>
            <td class="text-right"><span v-html="numberFormat(-item.amounts.won)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.balance)"></span></td>
          </tr>
          <tr v-if="group.markets.length > 0">
            <th class="text-center">รวม {{group.item_name}}</th>
            <th class="text-right"><span v-html="numberFormat(group.total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(group.total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(group.total.balance)"></span></th>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0" style="border-top: 3px double #ccc;">
          <tr style="font-size: 130%">
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.balance)"></span></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import AppConfig from '@/configs/app.config.js'

import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import _ from 'lodash'

import Swal from 'sweetalert2'

import moment from '@/helpers/moment'

import ReportService from '@/services/ReportService'

import BarChart from '@/views/components/BarChart'
import CardDateFrom from '@/views/components/CardDateFrom'

export default {
  name: 'BranchReportAgent',
  components: {
    Loading,
    BarChart,
    CardDateFrom
  },
  data() {
    return {
      isLoading: false,
      dataDate: null,
      successDataDate: null,
      selectedBranch: null,
      data: null,

      chartSelected: ['sales', 'wons'],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      },
      branchFilters: []
    }
  },
  computed: {
    isManager() {
      const LoggedInData = JSON.parse(localStorage.getItem(AppConfig.key.LoggedIn))
      return /*LoggedInData?.userRole === 'Owner' ||*/ LoggedInData?.role === 'Manager' || LoggedInData?.role === 'Admin'
    },
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start}`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end}`
      }
    },
    Groups() {
      if(!this.data)
        return []

      return this.data.Groups.map((group)=>{

        const markets = this.Markets.filter((market)=>{
          return market.groupId === group._id
        })

        const total = markets.reduce((total, market)=>{
          total.sales += parseFloat(market?.amounts?.sales || 0)
          total.won -= parseFloat(market?.amounts?.won || 0)
          total.balance += parseFloat(market?.amounts?.balance || 0)
          total.commission += parseFloat(market?.amounts?.commission || 0)
          total.company += parseFloat(market?.amounts?.company || 0)
          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0,
          commission: 0,
          company: 0
        })

        group.markets = markets
        group.total = total
        return group
      })
    },
    Markets() {
      if(!this.data)
        return []

      return this.data.Markets.map((market)=>{

        const amounts = this.items.reduce((total, item)=>{
          const marketId = market._id.toString()
          total.sales += parseFloat(item?.markets[marketId]?.amount?.sales || 0)
          total.won += parseFloat(item?.markets[marketId]?.amount?.won || 0)
          total.balance += parseFloat(item?.markets[marketId]?.amount?.balance || 0)
          total.commission += parseFloat(item?.markets[marketId]?.amount?.grandCommission || 0)
          total.company += parseFloat(item?.markets[marketId]?.amount?.company || 0)
          return total
        }, {
          sales: 0,
          won: 0,
          balance: 0,
          commission: 0,
          company: 0
        })

        return {
          item_id: market._id,
          item_name: market.item_name,
          groupId: market?.owners?.mGroupId,
          amounts: amounts
        }
      })
    },
    items() {
      if(!this.data)
        return []

      return this.data?.items.filter((item)=>{
        if(!this.branchFilters.length)
          return true

        return this.branchFilters.includes(item.owners.accountId)
      })
    },
    total() {
      return this.Markets
      .reduce((total, d)=>{
        total.sales += d.amounts.sales || 0
        total.won -= d.amounts.won || 0
        total.balance += d.amounts.balance || 0
        total.commission += d.amounts.commission || 0
        total.company += d.amounts.company || 0
        return total
      }, {
        sales: 0,
        won: 0,
        balance: 0,
        commission: 0,
        company: 0
      })
    },
    chartSelectOptions() {
      let options = []
      options.push({ text: 'ยอดขาย', value: 'sales' })
      options.push({ text: 'ยอดจ่าย', value: 'wons' })
      return options
    },
    chartCollection() {

      const labels = this.Markets.map((item)=>{
        return item.item_name
      })

      let datasets = {}
      datasets.sales = {
        label: 'ยอดคีย์',
        backgroundColor: '#c3e6cb',
        data: this.Markets.map((item)=>{
          return item.amounts.sales || 0
        })
      }

      datasets.wons = {
        label: 'ยอดจ่าย',
        backgroundColor: '#f5c6cb',
        data: this.Markets.map((item)=>{
          return item.amounts.won || 0
        })
      }

      datasets.commission = {
        label: 'ค่าคอมฯ',
        backgroundColor: '#bee5eb',
        data: this.Markets.map((item)=>{
          return item.amounts.commission || 0
        })
      }

      datasets.company = {
        label: 'บริษัทฯ',
        backgroundColor: '#b8daff',
        data: this.Markets.map((item)=>{
          return item.amounts.company || 0
        })
      }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    },

    Branchs() {
      return this.data?.Branchs || []
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date
      this.selectedBranch = params?.branch

      this.isLoading = true
      ReportService.getMarkets(this.dataDate, this.selectedBranch)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    },
    webname(id) {
      return this.Websites.find((web)=>{
        return web._id === id
      })?.item_name
    }
  },
  filters: {
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    }
  }
}
</script>
<style lang="scss" scoped>
#agent-report {
  position: relative;
  padding-bottom: 50px;

  .view-info {
    cursor: pointer;
  }
}
</style>
